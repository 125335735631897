/* eslint-disable no-sequences */
/* eslint-disable one-var */
/* eslint-disable no-var */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */

// Main Vars
require('./src/styles/utils/variables/_colors.scss');
require('./src/styles/utils/variables/_main.scss');
require('./src/styles/base/_typography.scss');
require('./src/styles/base/_reset.scss');
require('./src/styles/base/_spacing.scss');
require('./src/styles/layouts/_grid.scss');

function initGTM() {
  if (window.loadGTM === false || window.gtmDidInit) {
    return false;
  }

  window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
  const trackingLink = `https://www.googletagmanager.com/gtm.js?id=${process.env.GA_TRACKING_ID}`;

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = trackingLink;

  script.onload = () => {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      // ensure PageViews are always tracked
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
      'gtm.uniqueEventId': 0,
    });
  };
  document.head.appendChild(script);

  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = trackingLink;
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  iframe.height = 0;
  iframe.width = 0;
  noscript.innerHTML += iframe.outerHTML;
  document.body.appendChild(noscript);
}

function optinMonster() {
  const script = document.createElement('script');
  script.id = 'optin-monstr';
  script.setAttribute('data-user', process.env.OPTINMONSTR_USER);
  script.setAttribute('data-account', process.env.OPTINMONSTR_ACC);
  script.src = 'https://a.omappapi.com/app/js/api.min.js';
  script.async = true;
  document.head.appendChild(script);
}

function crazyEgg() {
  const script = document.createElement('script');
  script.id = 'crazy-egg';
  script.src = 'https://script.crazyegg.com/pages/scripts/0120/2543.js';
  script.async = true;
  document.head.appendChild(script);
}

const loadFacebookPixel = () => {
  !(function (f, b, e, v, n, t, s) {
    // eslint-disable-next-line no-multi-assign
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod
        ? // eslint-disable-next-line prefer-spread, prefer-rest-params
          n.callMethod.apply(n, arguments)
        : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.id = 'pixel-code';
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
};

const microsoftAdvertising = () => {
  (function (w, d, t, r, u) {
    var f, n, i;
    (w[u] = w[u] || []),
      (f = function () {
        var o = { ti: '187106455', enableAutoSpaTracking: true };
        (o.q = w[u]), (w[u] = new UET(o)), w[u].push('pageLoad');
      }),
      (n = d.createElement(t)),
      (n.src = r),
      (n.async = 1),
      (n.id = 'microsoft-code'),
      (n.onload = n.onreadystatechange =
        function () {
          var s = this.readyState;
          (s && s !== 'loaded' && s !== 'complete') ||
            (f(), (n.onload = n.onreadystatechange = null));
        }),
      (i = d.getElementsByTagName(t)[0]),
      i.parentNode.insertBefore(n, i);
  })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
};

const piguard = () =>{
  const script = document.createElement('script');
  script.id = 'piguard';
  script.src = `https://pg.${process.env.GATSBY_SITE_NAME}/analytics.js?tid=PP-${process.env.PIGUARD_ID}`;
  script.async = true;
  document.head.appendChild(script);
}

const disquss = () =>{
   // eslint-disable-next-line no-unused-vars, func-names, camelcase
   var disqus_config = function () {
    this.page.url = window.location.href;
    this.page.identifier = window.location.pathname; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
  };

  var d = document,
    s = d.createElement('script');
  s.src = `${process.env.DISQUSS_URL}`;
  s.id = 'disquss-code';
  s.setAttribute('data-timestamp', +new Date());
  (d.head || d.body).appendChild(s);
}

function scrollEvent(event) {
  initGTM();

  if (!document.getElementById('piguard') && process.env.ENABLE_PIGUARD === 'true') {
    piguard();
  }

  if (!document.getElementById('disquss-code')  && process.env.ENABLE_DISQUSS === 'true') {
    disquss();
  }

  if (process.env.ENABLE_MICROSOFT === 'true' && !document.getElementById('microsoft-code'))
    microsoftAdvertising();

  if (process.env.ENABLE_OPTINMONSTR === 'true' && !document.getElementById('optin-monstr'))
    optinMonster();
  if (process.env.ENABLE_PIXEL === 'true' && !document.getElementById('pixel-code')) {
    if (
      typeof window !== 'undefined' &&
      window.location.pathname !== process.env.PAGE_EXCLUDE_PIXEL
    ) {
      loadFacebookPixel();

      fbq('init', process.env.PIXEL_ID);

      // Initialize and track the PageView event
      fbq('track', 'PageView');
    }
  }

  if (process.env.ENABLE_CRAZYEGG === 'true' && !document.getElementById('crazy-egg')) crazyEgg();

  if (event) {
    event.currentTarget.removeEventListener(event.type, scrollEvent); // remove the event listener that got triggered
  }
}

exports.onClientEntry = () => {
  if (process.env.PPC === 'true') {
    scrollEvent(null);
  } else {
    document.addEventListener('scroll', scrollEvent);
    document.addEventListener('mousemove', scrollEvent);
    document.addEventListener('touchstart', scrollEvent, { passive: true });
  }
};
